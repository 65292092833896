<template>
  <div class="col-12 col-md-8 mx-auto">
    <div class="row">
      <div class="col">
        <h1>Add Email</h1>
      </div>
    </div>
    <div class="card theme-card shadow-card">
      <div class="card-body">
        <form
          name="addEmail"
          autocomplete="off"
          validate
          @submit.prevent="addEmail"
          method="post"
        >
          <input-control control="email" :required="true" v-model="email"
            >Email</input-control
          >
          <save :saving="emails.isSaving">Save Email</save>
          <button
            v-if="src"
            class="btn btn-link ml-2"
            @click.prevent="navigateBack"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("providers", ["emails"]),
    src: function () {
      return this.$route.query.src;
    },
  },
  data() {
    return {
      email: null,
      userId: null,
    };
  },
  created() {
    this.$store
      .dispatch("providers/get", { id: this.$route.params.id })
      .then((res) => {
        this.userId = res?.user_id;
      });
  },
  methods: {
    addEmail: function () {
      let data = {
        provider_id: this.$route.params.id,
        user_id: this.userId,
        email: this.email,
        status: "ACTIVE",
      };
      this.$store.dispatch("providers/emails/create", data);
    },
    navigateBack: function () {
      this.$router.go(-1);
    },
  },
};
</script>
